import { defineStore } from 'pinia'

interface User {}
interface UserProfile {}

const userLocalKey = 'user'
const userProfileLocalKey = 'user_info'

function getUserFromLocalStorage() {
  return localStorage.getItem(userLocalKey) ? JSON.parse(localStorage.getItem(userLocalKey) as string) : null
}

function getUserProfileFromLocalStorage() {
  return localStorage.getItem(userProfileLocalKey)
    ? JSON.parse(localStorage.getItem(userProfileLocalKey) as string)
    : null
}

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore('user', () => {
  // other options...
  const user = ref<null | any>(getUserFromLocalStorage())

  const isLoggedIn = computed(() => (user.value ? true : false))

  const setUserToLocalStorage = (data: Record<string, any>) => {
    localStorage.setItem('user', JSON.stringify(data))
    user.value = data
  }
  const removeUserFromLocalStorage = () => {
    localStorage.removeItem('user')
    user.value = null
  }

  return {
    user,
    isLoggedIn,
    setUserToLocalStorage,
    removeUserFromLocalStorage,
  }
})

export const useUserProfileStore = defineStore('userProfile', () => {
  // other options...

  const userProfile = ref<null | any>(getUserProfileFromLocalStorage())

  const setUserProfileToLocalStorage = (data: Record<string, any>) => {
    localStorage.setItem(userProfileLocalKey, JSON.stringify(data))
    userProfile.value = data
  }

  const removeUserProfileFromLocalStorage = () => {
    localStorage.removeItem(userProfileLocalKey)
    userProfile.value = null
  }

  return {
    userProfile,
    setUserProfileToLocalStorage,
    removeUserProfileFromLocalStorage,
  }
})
